import React, { useEffect, useState } from 'react';
import { Alert, Link, Text } from '@solvhealth/jigsaw-v1';
import styled from 'styled-components';
import { analyticsTrackEvent } from '~/core/analytics';
import { isNativeApp } from '../../core/util/device';
import { CCPA_CONSENT_BANNER_POLICY_LINK } from '~/core/analytics/events';
import history from '~/core/history';
import { getItemInLocalStorage, setItemInLocalStorage } from '~/core/util/storage';
import { colors } from '~/constants/colors';

// left position is calc(50% - 17px) because the banner has a close icon section on the right that has a width of 34px.
// z-index is 1300 because it should be higher than most of pages' content but lower than modals' (login modal etc.) content, which has a value of 1400.
const AlertContainer = styled.div.withConfig({ componentId: 'sc-wyl18p-0' })`
  position: fixed;
  bottom: 50px;
  left: calc(50% - 17px);
  transform: translate(-50%, 0);
  z-index: 1300;
  width: 87%;
  max-width: 912px;
  & svg {
    color: ${colors.crunchBerry};
  }
`;

const CCPAConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(!isNativeApp() && !getItemInLocalStorage('ccpaConsentBannerShown'));
    setItemInLocalStorage('ccpaConsentBannerShown', 'true');
  }, []);

  useEffect(() => {
    if (!isNativeApp() && showBanner) {
      let unlisten = history.listen(() => {
        setShowBanner(false);
        unlisten();
        unlisten = () => null;
      });

      return () => {
        try {
          unlisten();
          unlisten = () => null;
        } catch (err) {
          // nothing really needed here, if unlisten throws an error that means the listener has already been removed.
          return;
        }
      };
    }
    return undefined;
  }, [showBanner]);

  if (!showBanner) return null;

  const handleLinkClick = () => {
    analyticsTrackEvent(CCPA_CONSENT_BANNER_POLICY_LINK);
    window.location.href = '/privacy';
  };

  return (
    <AlertContainer>
      <Alert
        collapsible
        icon={null}
        onCollapse={() => setShowBanner(false)}
        show
        tone="message"
        variant="inline"
      >
        <Text as="span" variant="body">
          This site uses cookies to provide you with a great user experience. By using Solv, you
          accept our{' '}
        </Text>
        <Link inline onClick={handleLinkClick} subtle sx={{ textDecoration: 'underline' }}>
          use of cookies.
        </Link>
      </Alert>
    </AlertContainer>
  );
};

export default CCPAConsentBanner;
