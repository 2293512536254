import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import { EN } from '~/core/util/localizedStr';
import AuthenticationProvider from '../../core/login/AuthenticationProvider';
import ErrorPage from '../../routes/error/ErrorPage';
import AccountWrapper from '../Account/AccountWrapper';
import { listenToAppInstalled, listenToBeforeInstallPrompt } from '../AddToHomeScreen/util';
import ChatLoader from '../Chat/ChatLoader';
import ErrorBoundary from '../ErrorBoundary';
import { ModalController } from '../ModalController';
import RefreshAccessToken from './RefreshAccessToken';
import UpdateAppModal from './UpdateAppModal';
import CCPAConsentBanner from './CCPAConsentBanner';
import { trackIfSiteLoadedWithinIframe } from '~/components/App/util/tracking';
import LiveReloadLoadingIndicator from '../util/FastRefreshLoadingIndicator';
import { EnableIntercomChat } from '../../core/intercom/EnableIntercomChat';
import NativeContextProvider from '~/core/native/NativeContextProvider';

const ENABLE_REACT_QUERY_DEVTOOLS = false;

moment.locale(EN);

const ContextType = {
  insertCss: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  setMeta: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
};

type Props = {
  context: any;
  error?: any;
  cookies: any;
  route?: any;
};

const queryClient = new QueryClient();

class App extends Component<Props> {
  static childContextTypes = ContextType;

  /**
   * Used to provide react a way ot know the types of contexts
   * children will get.
   *
   * Removing this will cause all other context queries to break.
   * Once we remove all old context apis, this function can also
   * be removed.
   *
   * @returns {ContextType} context
   */
  getChildContext = () => this.props.context;

  componentDidMount = () => {
    listenToBeforeInstallPrompt();
    listenToAppInstalled();
    // tracking this temporarily for veracode flaw (ONION-238) triaging to help inform our mitigation
    trackIfSiteLoadedWithinIframe();
  };

  render = () => {
    const { error, route, cookies } = this.props;

    if (error) return <ErrorPage error={error} />;

    const accountWrapperConfig = (route && route.accountWrapper) || {};
    const { loginRequired, returnAfterLogin, nonBlocking } = accountWrapperConfig;

    return (
      <ErrorBoundary fallbackUI={<ErrorPage />}>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          {ENABLE_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
          <RefreshAccessToken cookies={cookies} />
          <AuthenticationProvider>
            <NativeContextProvider>
              <AccountWrapper
                loginRequired={loginRequired}
                nonBlocking={nonBlocking}
                returnAfterLogin={returnAfterLogin}
              >
                {this.props.children}
              </AccountWrapper>

              <ModalController />
              <CCPAConsentBanner />
              <ChatLoader />
              {typeof window !== 'undefined' && <EnableIntercomChat />}
              {__DEV__ && <LiveReloadLoadingIndicator />}
            </NativeContextProvider>
          </AuthenticationProvider>
          <ErrorBoundary fallbackUI={null}>
            <UpdateAppModal />
          </ErrorBoundary>
        </QueryClientProvider>
      </ErrorBoundary>
    );
  };
}

export default withCookies(App);
